@tailwind base;
@tailwind components;
@tailwind utilities;

img {
  max-width: inherit;
}

@layer components {
  html {
    @apply overflow-auto !important;
  }
  body {
    @apply select-none;
  }
  .h1-title {
    @apply font-normal text-primary-text text-6xl whitespace-pre-line;
  }
  h1 {
    @apply font-semibold text-primary-text text-3xl whitespace-pre-line;
  }
  h2 {
    @apply font-semibold text-primary-text text-lg whitespace-pre-line;
  }
  h3 {
    @apply font-medium text-primary-text text-base whitespace-pre-line;
  }
  .h3-semibold {
    @apply font-semibold text-primary-text text-base whitespace-pre-line;
  }
  p {
    @apply font-normal text-primary-text text-base whitespace-pre-line;
  }
  .body-article {
    @apply font-normal text-primary-text text-base leading-[29px] tracking-[0.015em] whitespace-pre-line;
  }
  .body-small {
    @apply font-normal text-primary-text text-sm whitespace-pre-line;
  }
  .body-semibold-small {
    @apply font-semibold text-primary-text text-sm whitespace-pre-line;
  }
  .body-secondary {
    @apply font-medium text-primary-text text-xs whitespace-pre-line;
  }
  .text-help {
    @apply font-medium text-primary-text text-xs whitespace-pre-line;
  }
  .uppercase {
    @apply font-semibold text-primary-text text-xs whitespace-pre-line;
  }
  .lowercase {
    @apply font-semibold text-primary-text text-2xs whitespace-pre-line;
  }

  input:focus {
    @apply outline-none ring-0 !important;
  }
  textarea:focus {
    @apply outline-none ring-0 !important;
  }
  select:focus {
    @apply outline-none ring-0 !important;
  }

  /* stepper */
  .stepper-item {
    @apply relative flex flex-col justify-center items-center w-full;
  }
  .stepper-item:not(:first-child):before {
    @apply content-[''] w-full h-[4px] absolute right-2/4 top-[9px] -translate-y-2/4 bg-gray-600;
  }
  .stepper-item .step {
    @apply z-10 relative bg-white;
  }
  .stepper-item.active p,
  .stepper-item.complete p {
    @apply text-gray-800;
  }
  .stepper-item.complete:not(:first-child):before,
  .stepper-item.active:not(:first-child):before {
    @apply bg-gray-800;
  }
}

/* datepicker */
.react-datepicker {
  font-family: "Kanit", "sans-serif" !important;
  border: 1px solid #c1c3cb;
  border-radius: 15px !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__header {
  font-size: 16px !important;
  padding: 8px 5px !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom: none !important;
  background-color: #ffffff !important;
}
.react-datepicker__month {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin: 0 !important;
  padding: 0px 20px 20px 20px;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 18%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 10%);
}
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2rem !important;
  line-height: 1.8rem !important;
  color: #000000 !important;
}
.react-datepicker__day--today {
  font-weight: normal !important;
}
.react-datepicker__day-name {
  width: 2rem !important;
  line-height: 1.8rem !important;
  color: #2b363b !important;
}
.react-datepicker__current-month {
  padding: 5px 0;
  font-weight: 500 !important;
  color: #2b363b !important;
  font-size: 1.3rem !important;
}
.react-datepicker__month-select,
.react-datepicker__year-select {
  color: #2b363b !important;
  border: none;
}
.react-datepicker__navigation-icon::before {
  border-color: #2b363b !important;
}
.react-datepicker__navigation--previous {
  left: 25px !important;
  top: 17px !important;
}
.react-datepicker__navigation--next {
  right: 25px !important;
  top: 17px !important;
}
.react-datepicker__day--selected {
  color: #ffffff !important;
  border-radius: 50% !important;
  background-color: #0f6aa9 !important;
}
.react-datepicker__day:hover {
  border-radius: 50% !important;
  background-color: transparent !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  display: none !important;
}
.react-datepicker__month-dropdown-container--select {
  padding-right: 10px;
}
.react-datepicker__day-names {
  font-weight: 500;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #c1c3cb !important;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent !important;
  color: #c1c3cb !important;
}

/* package */
.package-detail-table td {
  font-size: 15px;
  font-weight: 300;
  padding: 3px;
}
.package-detail-table tr {
  vertical-align: baseline;
}
.package-detail-table th {
  font-weight: 400;
  padding: 3px;
}

/* iframe */
iframe#kyc-iframe {
  width: 100%;
  height: 85vh;
}
iframe#payment-iframe.iframe-scale-50 {
  width: 200%;
  height: calc(200% - 146px); /* header 146px */
  transform: scale(0.5);
  transform-origin: 0 0;
}
iframe#payment-iframe.iframe-normal {
  width: 100%;
  height: 85vh;
}

/* condition insure */
ul.dialog-condition {
  list-style: disc;
}
